import { render, staticRenderFns } from "./FeeReceiptTable.vue?vue&type=template&id=1295558b&scoped=true&"
import script from "./FeeReceiptTable.vue?vue&type=script&lang=js&"
export * from "./FeeReceiptTable.vue?vue&type=script&lang=js&"
import style0 from "./FeeReceiptTable.vue?vue&type=style&index=0&id=1295558b&lang=css&scoped=true&"
import style1 from "./FeeReceiptTable.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1295558b",
  null
  
)

export default component.exports