<template>
  <div class="fee-receipt-table mx-8 my-4">
    <table style="width: 100%;background-color:white;" class="table" v-if="receipt">
      <tbody>
        <tr style="height: 30px;">
          <td
            class="border-right-none"
            style="height: 44px;padding-left:20px;text-align:left;"
            colspan="2"
            rowspan="2"
          >
            <img :src="currentInstitution.logo" :height="`${receipt.settings.logo_size ? receipt.settings.logo_size : '140' }px`" />
          </td>
          <td class="border-bottom-none border-sides-none" colspan="8">
              <img v-if="receipt.settings.font_image" :src="receipt.settings.font_image" style="max-height:48px;max-width:100%;padding-top:8px;"/> 
             <span v-else class="bold" style="height: 22px; font-size: 24px;">{{ currentInstitution.name}}</span>
            <div class="html-header ql-editor">
              <div v-html="receipt.header_content" class="py-0"></div>
              <!-- <quill v-model="receipt.header_content"></quill> -->
            </div>
          </td>
          <td class="border-left-none" style="height: 60px;" colspan="2" rowspan="2">{{copyName || "PARENT COPY"}}</td>
        </tr>
        <tr style="height: 22px;">
          <td class="border-top-none border-sides-none" colspan="8"><b>Fee Receipt ({{ Helper.getFiscalYear(new Date(receipt.payment_date)) }})</b></td>
        </tr>
        <tr style="height: 22px;">
          <td class="border-none" style="height: 30px;" colspan="2">Student Name :</td>
          <td
            class="border-none text-left bold"
            style="height: 30px;"
            colspan="6"
          >{{ receipt.fee_account.student.full_name }}</td>
          <td class="border-none" style="height: 30px;" colspan="2">Receipt Number :</td>
          
          <td
            class="border-none text-left bold"
            style="height: 30px;"
            colspan="6"
          >{{ receipt.receipt_number }}</td>
        </tr>
        <tr style="height: 22px;">
          <!-- <td class="border-none" style="height: 30px;" colspan="2">Roll Number :</td>
          <td
            class="border-none text-left bold"
            style="height: 30px;"
            colspan="4"
          >{{ receipt.fee_account.student.roll_number }}</td> -->
          <td class="border-none" style="height: 30px;" colspan="2">GR Number :</td>
          <td
            class="border-none text-left bold"
            style="height: 30px;"
            colspan="6"
          >{{ receipt.fee_account.student.gr_no }}</td>
          
          <td class="border-none" style="height: 30px;" colspan="2">Payment Date :</td>
          <td
            class="border-none text-left bold"
            style="height: 30px;"
            colspan="6"
          >{{ moment(receipt.payment_date).format("DD-MMM-YYYY") }}</td>
          
        </tr>
        <tr style="height: 22px;">
          <!-- <td class="border-none" style="height: 30px;" colspan="2">Student Category :</td>
          <td
            class="border-none text-left bold"
            style="height: 30px;"
            colspan="4"
          >{{ receipt.fee_account.category.code }}</td> -->
          <td class="border-none" style="height: 30px;" colspan="2">Contact Number :</td>
          <td
            class="border-none text-left bold"
            style="height: 30px;"
            colspan="6"
          >{{ receipt.fee_account.student.phone }}</td>
        <td class="border-none" style="height: 30px;" colspan="2">Class :</td>
          <td
            class="border-none text-left bold"
            style="height: 30px;"
            colspan="6"
          >{{ receipt.fee_account.room.sections }}</td>

        </tr>
        <tr style="height: 30px;">
          <th style="height: 30px;" colspan="1">Sr. No</th>
          <th style="height: 30px;" colspan="4">Particulars</th>
          <th style="height: 30px;" colspan="3">Instalment</th>
          <th style="height: 30px;" colspan="2">Due Amount</th>
          <th style="height: 30px;" colspan="2">Amount Paid</th>
        </tr>
        <tr
          style="height: 30px;"
          v-for="(money_transaction, index) in receipt.money_transactions"
          :key="money_transaction.id"
        >
          <td style="height: 30px;" colspan="1">{{ index + 1}}</td>
          <td
            style="height: 30px;"
            colspan="4"
          >{{ money_transaction.particular_title }}<span v-if="receipt.session && serverActiveSession && receipt.session.id != serverActiveSession.id"> ({{receipt.session.title}})</span></td>
          <td
            style="height: 30px;"
            colspan="3"
          >{{ money_transaction.schedule_instalment_title }}</td>
          <td
            style="height: 30px;"
            colspan="2"
          >{{ currencyFormat(Number(money_transaction.due_amount) || 0) }}<span v-if="money_transaction.concession > 0"><b> (-{{currencyFormat(Number(money_transaction.concession) || 0)}})</b></span><span v-if="money_transaction.fine > 0"><b> (+{{currencyFormat(Number(money_transaction.fine) || 0)}})</b></span></td>
          <!-- <td
            style="height: 30px;"
            colspan="2"
          >{{currencyFormat(parseInt(money_transaction.amount_paid || 0) + (parseInt(money_transaction.concession) || 0) - parseInt(money_transaction.fine))}}</td> -->
          <td
            style="height: 30px;"
            colspan="2"
          >{{currencyFormat(parseInt(money_transaction.amount_paid || 0))}}</td>
        </tr>
        <tr v-for="r in getExtraRows()" :key="r">
          <td style="height: 30px;" colspan="1"></td>
          <td style="height: 30px;" colspan="4"></td>
          <td style="height: 30px;" colspan="3"></td>
          <td style="height: 30px;" colspan="2"></td>
          <td style="height: 30px;" colspan="2"></td>
        </tr>
        <!-- <tr style="height: 30px;" v-if="receipt.settings.show_concession">
          <td style="height: 30px;" colspan="1">&nbsp;</td>
          <td style="height: 30px;" class="bold" colspan="8">Concession</td>
          <td
            style="height: 30px;"
            class="bold"
            colspan="2"
          >{{ currencyFormat(receipt.total_concession) }}</td>
        </tr> -->
        <tr v-if="receipt.total_fine > 0" style="height: 30px;">
          <td style="height: 30px;" colspan="1">&nbsp;</td>
          <td style="height: 30px;" class="bold" colspan="8">Fine</td>
          <td
            style="height: 30px;"
            class="bold"
            colspan="2"
          >{{ currencyFormat(receipt.total_fine) }}</td>
        </tr>
        <tr style="height: 30px;">
          <td style="height: 30px;" colspan="1">&nbsp;</td>
          <td style="height: 30px;" class="bold" colspan="8">Total Paid</td>
          <td
            class="bold"
            style="height: 30px;"
            colspan="2"
          >{{ currencyFormat(receipt.total_amount_paid) }}</td>
        </tr>
        <tr v-if="receipt.show_total_balance" style="height: 30px;">
          <td style="height: 30px;" colspan="1">&nbsp;</td>
          <td style="height: 30px;" class="bold" colspan="8">Total Balance Amount</td>
          <td
            class="bold"
            style="height: 30px;"
            colspan="2"
          >{{ currencyFormat(receipt.total_balance_post_payment) }}</td>
        </tr>
        <tr style="height: 30px;">
          <td colspan="1" class="bold">Amount in words</td>
          <td colspan="10" class="text-left px-4"><b>{{ inWords(parseInt(receipt.total_amount_paid)).toUpperCase() }}</b></td>
        </tr>
        <tr style="height: 30px;">
          <td colspan="1" class="bold">Mode</td>
          <td colspan="3">{{ receipt.mode }}</td>
          <td colspan="2" class="bold">Remarks</td>
          <td colspan="5" class="text-left px-4">{{ receipt.remarks || "-"  }}</td>
        </tr>

        <tr style="height: 80px">
          <td colspan="10" style="font-size: 14px;" class="text-left px-4">
            <div v-html="receipt.settings.notes" class="py-0">
              
            </div>
          </td>
          <td colspan="2" style="vertical-align: bottom;">
            <div>
              <img v-if="receipt.settings.signature" :src="receipt.settings.signature" height="40px" />

            </div>
            <b>Cashier/Manager</b>
          </td>
        </tr>


      </tbody>
    </table>
    <p style="page-break-before: always" v-if="(parseInt($route.params.count) == 2) && (copyName == 'OFFICE COPY')"/>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import Helper from "@utils/misc";
import moment from "moment";
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

export default {
  name: "ViewFeeReceipt",
  props: ["receipt", "copyName"],
  data() {
    return {
      moment: moment,
      Helper: Helper,
    };
  },
  computed: {
    ...mapGetters([
      "currentUser",
      "currentInstitution",
      "accessToken",
      "isLoading",
      "feeSessionData"
    ]),
    essentials() {
      return {
        accessToken: this.accessToken,
        setLoading: this.setLoading,
        handleErrorsFunction: this.handleApiErrors,
      };
    },
    serverActiveSession() {
      return this.feeSessionData.find((s) => s.active);
    }
  },
  methods: {
    ...mapActions(["setLoading"]),
    currencyFormat: Helper.currencyFormat,
    inWords: Helper.inWords,
    getExtraRows(){
      let extra_rows = this.receipt.particular_rows - this.receipt.money_transactions.length
      console.log(extra_rows)
      return (extra_rows > 0 ? extra_rows : 0)
    }
  },
  created() {
    console.log(this.receipt);
  }
};
</script>

<style lang="css" scoped>
.bold {
  font-weight: bold;
}
table,
th,
td {
  border: 1px solid black;
  border-collapse: collapse;
}
.border-bottom-none {
  border-bottom: none;
}
.border-top-none {
  border-top: none;
}
.border-edges-none {
  border-top: none;
  border-bottom: none;
}
.border-left-none {
  border-left: none;
}
.border-right-none {
  border-right: none;
}
.border-sides-none {
  border-left: none;
  border-right: none;
}
.border-none {
  border: none;
}
</style>

<style>
#app.html-header{
  /* all: initial; */
  /* text-align:  */
  /* all: initial; */
  /* font: inherit; */
  /* align-content: unset; */
  /* width: 100%; */
}

</style>